<template>
    <div class="bloc-modale" v-if="revele">
        <div class="overlay"> </div>
        <div class="modale card ">
            <div class="btn btn-danger close-btn" @click="toggleForm">X</div>
            <div class="card-modal mt-4">
                <div class="input-group mt-3">
                    <label for="montant">MONTANT</label>
                    <input class="" type="number" v-model="data.montant" name="">  
                </div>
                <div class="input-group mt-3">
                        <label for="">DATE DE DEBUT</label>
                        <select id="my-select" class="form-control" v-model="data.date_debut" name="">
                            <option v-for="m in months.length " v-bind:value="m" >{{months[m-1]}}</option>
                        </select>
                        <input class="form-control" type="number" v-model="data.annee" placeholder="Année"  name="">
                </div>

                <div class="input-group mt-3">
                    <label for="montant">PERIODE</label>
                    <input class="" type="number" placeholder="EN MOIS" v-model="data.periode" name="">
                    
                </div>

                <div class="input-group mt-3">
                    <label for="montant">TAUX </label>
                    <input class="" type="number" v-model="data.taux" name="">
                   
                </div>
                <div class="input-group">
                    <label for="montant"> </label>
                    <button @click="saveEpargne" class="butn butn-info">Valider</button>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props:["revele","toggleForm"],

    data() {
        return {
            data : {
                montant : "",
                periode : "",
                taux: "",
                date_debut : "",
                annee : ""

            },
            product : "JEAN LIONEL",
        }
    },
    computed:{
         months(){
           return  [
                    'Janvier',
                    'Février',
                    'Mars',
                    'Avril',
                    'Mai',
                    'Juin',
                    'Juillet',
                    'Août',
                    'Septembre',
                    'Octobre',
                    'Novembre',
                    'Décembre'
                    ] 
        }

    },
    methods:{
        saveEpargne(){
           
           this.$emit('doSomething', this.data)
           this.toggleForm()

        }

    },
    watch:{
        
    }
    
}
</script>

<style scoped>
.input-group{
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}
.input-group label{
    display: block;
    width: 100px;
    text-align: left;
}
.input-group input {
    display: block;
    width: 100%;
}

/** POUR LE MODAL */
.bloc-modale{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 150;
}

.overlay{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

}

.modale{
    position:fixed;
    top:10%;
    height: auto;

     
}
.card-modal
{
    padding: 20px;
    width: auto;
    
    
}  

.close-btn{
    position: absolute;
    top:5px;
    right:5px;
    cursor:pointer;
    display: block;
    padding: 5px 12px;
    border-radius: 3px;
    color: #fff;
    background: #dc3545;
   
}
.butn {
    width: 100%;
}
</style>