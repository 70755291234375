<template>
	<div>
		<div class="d-flex justify-content-between w-100">
			<searchbar />
			<div v-if="messageInfo">
				<div class="alert alert-primary" role="alert">
					{{ messageInfo }}
				</div>
			</div>
			<button class="butn butn-primary add" @click="printMember">
				Imprimer
			</button>

			<button class="butn butn-primary add" @click="toggleForm">
				Ajouter un membre
			</button>
			
		</div>
		<ModaleMember
			@updateMessage="updateMessage"
			:class="{ hidden: !revele }"
			@close="revele = false"
			:selectedMembre="selectedMembre"
		/>
		<h3 class="d-inline-block text-center mt-4 btn btn-info"
			>Liste des membres
		</h3>
		<member-list
			@modifierMembre="modifierMembre"
			@toggleForm="toggleForm"
			:searchName="searchName"
			class=" mt-3"
		/>
			<div id="print" class="hidden">

				<div class="header_print">
					<div>
					<h2>CAISSE SOCIAL</h2>
					</div>

					<div> 
					<h2> {{new Date().toDateString()}} </h2>
					</div>
				
				 </div>
				<table class="">
					<thead>
						<tr>
						<th> NOM </th>
						<th> PRENOM </th>
						<th> EPARGNE </th>
						<th> SOCIAL</th>
						<th>TOTAL EPARGNE </th>
						<th>TOTAL SOCIAL</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="membre in liste_membres" :key="membre.id">
							<td>
							{{membre.user.first_name}}
							</td>
							<td>
							{{membre.user.last_name}}
							</td>
							<td>
							{{membre.somme_epargne}}
							</td>
							<td>
							{{membre.somme_entraide}}
							</td>
							<td>
							{{membre.total_epargne}}
							</td>
							<td>
							{{membre.total_entraide}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
	</div>


</template>

<script>
import MemberList from "../components/MemberList.vue";
import ModaleMember from "../components/ModaleMember.vue";
import searchbar from "../components/SearchBar.vue";
import axios from 'axios'

export default {
	components: { MemberList, ModaleMember, searchbar },
	data() {
		return {
			revele: false,
			selectedMembre: {},
			searchName: "",
			messageInfo: "",
		
			
		};
	},
	mounted() {
			
			axios.get(this.$store.state.url+"/compte/all/",this.header)
			.then(res => {
			
				this.$store.state.liste_membres = res.data
			})
			.catch(err => {
				console.error(err); 
			})

		
	},
	methods: {
		toggleForm() {
			this.selectedMembre = {};
			this.revele = !this.revele;
		},
		modifierMembre(ele) {
			this.revele = true;
			this.$store.state.save_user = false;
		},
		updateMessage(message) {
			this.messageInfo = message;
		},
		printMember(){
			//let liste_membres = []
		
			const prtHtml = document.getElementById('print').innerHTML;
			const WinPrint = window.open('', '', `width=${screen.width},height=${screen.height},resizable=yes,fullscreen=yes`);

			WinPrint.document.write(`<!DOCTYPE html>
			<html>
			<head>
			<style>
			table{
				width : 100%;
				border-collapse : collapse;
			}

			table,tr,td,th{
				border: 1px solid #000;
			}
			.header_print{
				display: flex;
				justify-content: space-between;
			}

			</style>
				
			</head>
			<body>
				${prtHtml}
			</body>
			</html>`);

			WinPrint.document.close();
			WinPrint.focus();
			WinPrint.print();
			setTimeout(() => {
				WinPrint.close();
			}, 1000);
		}
	},
	computed:{
		header(){
            return{
				headers :{
					"Authorization" : `Bearer ${this.$store.state.user.access}`
				}
			}
        },
		liste_membres(){
			return this.$store.state.liste_membres
		}
	}
};
</script>
<style scoped>


.hidden {
	display: none;
}

h3 {
	font-size: 22px;
	text-transform: capitalize;
	margin-bottom: 15px;
}

.d-flex {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 15px;
}

@media screen and (max-width: 600px) {
	.main-container {
		max-width: 400px;
	}
	.d-flex {
		display: block;	
	}

	.butn {
		padding: 7px 12px;
		margin-top: 10px;
		margin-right: auto;
		margin-left: auto;
		display: block;
	}
}
</style>
