<template>
	<div>
		<h3>MES CREDITS</h3>

		<div class="tablea-area">
			<table class="table-content">
				<thead class="text-center">
					<tr>
						<td>DEMANDE DE CREDIT NO</td>
						<td>COMPTE</td>
						<td>MONTANT</td>
						<td>PERIODE</td>
						<td>DATE DE DEMANDE</td>
						<td>ACTION</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="credit in credits.results" :key="credit.id">
						<td>{{ credit.id }}</td>
						<td>{{ credit.compte }}</td>
						<td>{{ credit.montant }}</td>
						<td>{{ credit.periode }}</td>
						<td>{{ datetime(credit.date) }}</td>
						<td>
							<button class="btn btn-info mr-2">Validé</button>
							<button class="btn btn-danger">Annulé</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			credits: [],
		};
	},
	mounted() {
		axios
			.get(this.$store.state.url + "/demande_credit/", this.header)
			.then((res) => {
				console.log(res);
				//this.$store.state.credits = res.data
				this.credits = res.data;
			})
			.catch((err) => {
				console.error(err);
			});
	},
	computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
	},
};
</script>

<style scoped>
h3 {
	font-size: 25px;
	margin: 10px 0;
}
.butn-sm {
	margin-right: 15px;
	font-size: 16px;
}

/* Tables */
.table-area {
	width: 100%;
	overflow-x: auto;
}
.table-content {
	width: 100%;
	margin-top: 15px;
}
th {
	padding: 10px;
}
td {
	padding: 10px;
}
tbody tr:hover {
	background: #d7dde5;
	color: #2c3e50;
}
/* Tables End */

@media screen and (max-width: 600px) {
	.table-content {
		width: 700px;
	}
}
</style>
