<template>
	<div class="wrapper">
		<!-- ===== Header area ===== -->
		<div class="header">
			<div class="left-area">
				<h1 class="main-title">credit et epargne</h1>
				<span class="fa fa-bars menu-toggle" @click="openNav"></span>
			</div>
			<div class="right-area">
				<div class="username">
					<span>{{ user.fullname }}</span>
					<i class="fa fa-user fa-2x" aria-hidden="true"></i>
				</div>
			</div>
		</div>
		<!-- ===== Header area end ===== -->
		<hr />
		<!-- ===== Sidebar menu area ===== -->
		<div class="sidebar">
			<ul class="nav">
				<li class="nav-item" @click="closeNav">
					<router-link
						to="/"
						active-class="active"
						tag="button"
						exact
						class="side-btn"
					>
						<i class="fa fa-tachometer"></i>
						<span class="link-name">Dashboard</span>
					</router-link>
				</li>
				<li class="nav-item" @click="closeNav">
					<router-link
						v-if="user.is_admin"
						to="/depot/1"
						active-class="active"
						tag="button"
						exact
						class="side-btn"
					>
						<div v-if="user.is_admin" class="link-container">
							<i class="fa fa-dollar"></i>
							<span class="link-name">DEPOTS</span>
						</div>
					</router-link>
				</li>
				<li class="nav-item" @click="closeNav">
					<router-link
						to="/retait/1"
						active-class="active"
						tag="button"
						exact
						class="side-btn"
					>
						<i class="fa fa-undo"></i>
						<span class="link-name">RETRAITS</span>
					</router-link>
				</li>
				<li class="nav-item" @click="closeNav">
					<router-link
						to="/membre/1"
						active-class="active"
						tag="button"
						exact
						class="side-btn"
						v-if="user.is_admin"
					>
						<i class="fa fa-users"></i>
						<span class="link-name">MEMBRES</span>
					</router-link>
				</li>
				<li class="nav-item" @click="closeNav">
					<router-link
						to="/profil"
						active-class="active"
						tag="button"
						exact
						class="side-btn"
						v-if="!user.is_admin"
					>
						<i class="fa fa-users"></i>
						<span class="link-name">Profil</span>
					</router-link>
				</li>
				<li class="nav-item" @click="closeNav">
					<router-link
						to="/credit"
						active-class="active"
						tag="button"
						exact
						class="side-btn"
					>
						<i class="fa fa-handshake-o"></i>
						<span class="link-name">CREDITS</span>
					</router-link>
				</li>
				<li class="nav-item" @click="closeNav">
					<router-link
						to="/depense/1"
						active-class="active"
						tag="button"
						exact
						class="side-btn"
						v-if="user.is_admin"
					>
						<i class="fa fa-exchange"></i>
						<span class="link-name">DEPENSES</span>
					</router-link>
				</li>
				<li class="nav-item" @click="closeNav">
					<router-link
						to="/rapport"
						active-class="active"
						tag="button"
						exact
						class="side-btn"
						v-if="user.is_admin"
					>
						<i class="fa fa-file"></i>
						<span class="link-name">RAPPORT</span>
					</router-link>
				</li>
				
				<li class="nav-item last" @click="logout">
					<span class="logout">
						<i class="fa fa-power-off fa-2x" aria-hidden="true"></i>
					</span>
				</li>
				<li class="nav-item" @click="closeNav"></li>
			</ul>
		</div>
		<!-- ===== Sidebar menu area end ===== -->

		<!-- ===== Main container ====== -->
		<div class="main-container">
			<slot></slot>
		</div>
		<!-- ===== Main container end ====== -->
		<div class="spacer"></div>
		<!-- ===== Footer Area ===== -->
		<div class="footer">
			<span class="copy">Copyright &copy; <span class="brand">CSD Team</span></span>
			<hr>
			<span class="madeby">Developpé par <a href="http://www.hogi.bi" target="_blank" class="hogi">HOGI</a></span>
		</div>
		<!-- ===== Footer Area End ===== -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			user: this.$store.state.user,
			showCredit: false,
		};
	},
	methods: {
		logout() {
			if (confirm("êtes vous sur de vouloir vous deconnecter?")) {
				localStorage.removeItem("user");
				window.location = "/";
			}
		},
		toggleShowForm() {
			this.showCredit = !this.showCredit;
		},
		openNav() {
			var nav = document.querySelector('.sidebar');
			nav.classList.toggle('menu-active');
		},
		closeNav() {
			var nav = document.querySelector('.sidebar');
			nav.classList.remove('menu-active');
		},
	},
};
</script>
<style scoped>
.wrapper {
	min-height: 100vh;
	position: relative;
}
/*===== Header area styles ===== */
.header {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 50px;
	background: #20c997;
	color: white;
	z-index: 100;
	transition: padding 0.5s ease;
}
.left-area .main-title {
	display: inline-block;
	text-transform: uppercase;
	font-weight: 500;
	margin: 0;
	margin-right: 30px;
	color: white;
}
.left-area .menu-toggle {
	display: none;
	font-size: 22px;
	font-weight: 400;
	color: #fff;
	cursor: pointer;
}

.right-area .username {
	font-size: 14px;
	display: flex;
	align-items: center;
	height: 100%;
	padding: 3px 7px;
	border-radius: 30px;
}
.username:hover {
	background: #fffe;
	color: #20c997;
}
.right-area .username .fa {
	font-size: 22px;
	margin-left: 8px;
}

/*===== Header area styles end ===== */
hr {
	border: none;
	height: 1px;
	display: block;
	margin: 0;
	width: 100%;
	background: var(--grey);
}
/*===== Sidebar area styles ===== */
.sidebar {
	position: sticky;
	top: 0;
	left: 0;
	background: #20c997;
	height: 60px;
	width: 100%;
	transition: width 0.5s ease;
	padding: 0 50px;
	z-index: 5;
}

.nav {
	display: flex;
	list-style: none;
	height: 100%;
	align-items: center;
}
.nav-item {
	margin-right: 5px;
	display: block;
}
.last {
	margin-left: auto;
	padding: 2px 9px 0;
	border-radius: 50px;
	cursor: pointer;
}
.last:hover {
	background: #fffe;
}
.last:hover * {
	color: #20c997;
}
.last .logout {
	line-height: 35px;
	cursor: pointer;
	color: #fff;
}
.last .logout i {
	font-size: 22px;
	padding: 7px 0 0;
}

.sidebar .side-btn {
	width: 100%;
	height: 20px;
	padding: 5px 9px;
	background: transparent;
	color: #fff;
	font-size: 15px;
	text-transform: uppercase;
	height: auto;
	border-radius: 15px;
}
.sidebar .side-btn.active,
.sidebar .side-btn:hover {
	background: #fff;
	color: #20c997;
}

.sidebar i {
	padding-right: 10px;
	font-size: 18px;
}
.sidebar span.link-name {
	transition: display 0.5s ease;
}

/*===== Sidebar area styles end ===== */
.spacer {
	padding: 30px;
}
/*===== Main container area styles ===== */
.main-container {
	padding: 30px 50px;
	width: 100%;
}
/*===== Main container area styles end ===== */

/* ==== Footer Area */
.footer {
	height: 60px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background: #20c997;
	color: #fff;
	justify-content: center;
	position: absolute;
	width: 100%;
	bottom: 0;
}
.footer .brand {
	font-weight: 500;
	font-size: 18px;
}
.footer hr {
	width: 1px;
	border: none;
	height: 60%;
	margin:  0 8px;
	background: #fff;
}
.footer .hogi {
	font-weight: 700;
	font-size: 18px;
	text-decoration: none;
	color: #fff;
	outline: none;
}
.footer .hogi:hover {
	text-decoration: underline;
}
/* ==== Footer Area End */

/* ====== MEDIA QUERIES ====== */
@media screen and (max-width: 880px) {
	.sidebar {
		padding: 0 25px;
	}
	.main-container {
		padding: 30px;
	}
}
@media screen and (max-width: 600px) {
	.main-container {
		padding: 20px;
	}
	.header {
		position: sticky;
		width: 100%;
		top: 0;
		left: 0;
		padding: 0 20px;
		height: 50px;
		z-index: 10;
		border-bottom: 1px solid #fff;
	}
	.header .main-title {
		display: none;
	}
	.left-area .menu-toggle {
		display: inline-block;
	}
	hr {
		display: none;
	}

	.sidebar {
		display: none;
	}
	.menu-active {
		display: block;
		position: fixed;
		background: #20c997;
		height: auto;
		margin-top: 50px;
		z-index: 5;
		padding: 0 10px;
		animation: navOpened .6s;
	}
	.menu-active .nav {
		display: block;
		width: 100%;
	}
	.menu-active .nav-item {
		margin: 0;
		border: 1px solid #20c997;
		border-collapse: collapse;
	}
	.menu-active .side-btn {
		border-radius: 0;
		font-size: 18px;
		padding: 8px 10px;
	}
	.last {
		margin-top: 10px;
		margin-left: 0;
	}
	.last .logout {
		display: block;
		width: 100%;
		height: 100%;
	}

	.footer hr {
		display: block;
	}
}
/* ====== MEDIA QUERIES END ====== */

/* ==== Animation ==== */
@keyframes navOpened {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
/* ==== Animation End ==== */
</style>
