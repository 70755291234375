<template>
	<div>
		<h3>Liste des retraits</h3>
		<retrait-list />
	</div>
</template>

<script>
import RetraitList from "../components/RetraitList.vue";
export default {
	components: { RetraitList },
};
</script>

<style scoped>
h3 {
  font-size: 25px;
  text-transform: capitalize;
}
@media screen and (max-width: 576px) {
	.main-container {
		max-width: 430px;
	}
}
</style>
