<template>
    <div v-if="showPaiement">
        <h1>Hello</h1>
    </div>
</template>

<script>
export default {
    props: ["showPaiement"],
    data(){
        return {
            
        }
    }
    
}
</script>

<style scoped>

</style>