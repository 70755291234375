<template>
	<div class="bloc-modale" v-if="revele">
		<div class="overlay"></div>
		<div class="modale card">
			<div class="btn btn-danger close-btn" @click="close">X</div>
			<h5>Nouvelle Depense</h5>
			<span class="text-danger"> {{errorMessage}} </span>
			<div class="card-modal mt-4">
				<div class="field mt-2">
					<label for="somme">MONTANT</label>
					<input
						class=""
						type="number"
						v-model.number="form.montant"
						name=""
					/>
				</div>
				<div class="field mt-2">
					<label for="somme">TYPE DE DEPENSE</label>

					<select class="" v-model="form.type">
						<option value="entraide">ENTRAIDE</option>
						<option value="epargne">CREDIT ET EPARGNE</option>
					</select>
					<span class="text-danger" v-if="err.details">Ce champs est obligatoire</span>
				</div>
				<div class="field mt-2">
					<label for="somme">DESCRIPTION</label>
					<textarea v-model="form.details" class="details"></textarea>
					<span class="text-danger" v-if="err.details">Ce champs est obligatoire</span>
				</div>
				<div class="field mt-3">
					<div v-if="loader" class="d-flex justify-content-center">
						<div class="spinner-border text-warning" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<label for="montant"> </label>
					<button
						v-if="!loader"
						@click="saveDepense"
						class="butn butn-info"
					>
						Valider
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
  props: ["revele", "toggleForm", "comptes","depense"],
  data() {
    return {
      form: {
         montant: 0,
        details: "",
        type: "",
      },
      depense_id : "",
      loader : false,
      b : {},
      err: {},
	  errorMessage : ""
    };
  },
  watch:{
      depense(new_val){
    
          if(new_val){
              this.form = JSON.parse(JSON.stringify(new_val))
          }else{
              this.depense_id = null;
          }
      }
  }
  ,
  methods: {
    close(){
      this.form = {
         montant: 0,
        details: "",
        type: "",
      }
      this.depense_id = "",
      this.loader = false,
      this.b = {}
      this.$emit("close")
    },
    saveDepense() {
      this.loader = true
      axios.post(this.$store.state.url + "/depense/", this.form, this.header)
        .then((res) => {
          this.$store.state.depenses.unshift(res.data);
          this.toggleForm();
          this.loader  = false
          this.form = {
               montant: 0,
               details: "",
          }
         
        })
        .catch((err) => {
          this.err = err.response.data
		  this.loader = false
		 // this.errorMessage = "La somme demande n'est pas disponible"
		  this.errorMessage = err.response.data.status
          console.log(this.err )
          //console.error(err.response.data.details);
        });
    },
  },
  computed: {
    header() {
      return {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.access}`,
        },
      };
    },
  },
};
</script>

<style scoped>
.details {
	display: block;
	width: 100%;
}
.field {
	display: flex;
	justify-content: space-around;
	flex-direction: column;
   margin-top: 10px;
}
.field label,
.field select,
.field input {
	display: block;
	width: 100%;
	color: black;
	text-align: left;
	margin: 0;
}
.field select,
.field input {
	height: 35px;
}

/** POUR LE MODAL */
.bloc-modale {
	position: fixed;
	top: 15;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 250;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}

.modale {
	position: fixed;
	top: 10%;
	height: auto;
}
.card-modal {
	padding: 20px;
	width: auto;
}

h5 {
	margin: 5px 0 0;
}
.close-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	display: block;
	padding: 5px 12px;
	border-radius: 3px;
	color: #fff;
	background: #dc3545;
}
.butn {
	width: 100%;
}
</style>
