<template>
  <div>
    <div class="table-area">
      <table class="table-content">
        <thead>
          <tr>
            <th>A PAYE</th>
            <th>RESTE</th>
            <th>INTERET</th>
            <th>DATE PAYMENT</th>
            <th>DATE DE REMBOURSEMENT</th>
            <th>DONE</th>
            <th>ECHEANCE</th>
            <th>CREDIT</th>
            <th>COMPTE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="amortisement in amarortissements" :key="amortisement.id">
            <td>{{ formatNumber(amortisement.a_payer) }}</td>
            <td>{{ formatNumber(amortisement.reste) }}</td>
            <td>{{ formatNumber(amortisement.interet) }}</td>
            <td>{{ amortisement.date_payment }}</td>
            <td>{{ amortisement.paid_at }}</td>
            <td>{{ amortisement.done }}</td>
            <td>{{ amortisement.echeance }}</td>
            <td>{{ amortisement.credit }}</td>
            <td>{{ amortisement.compte }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      amarortissements: [],
    };
  },
  mounted() {
    const credit_id = this.$route.params.credit_id;
    axios
      .get(
        this.$store.state.url + "/amortissement/?credit=" + credit_id,
        this.header
      )
      .then((res) => {
        console.log(res);
        this.amarortissements = res.data.results;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  computed: {
    header() {
      return {
        headers: {
          Authorization: `Bearer ${this.$store.state.user.access}`,
        },
      };
    },
  },
};
</script>

<style scoped>
/* Tables */
.table-area {
  width: 100%;
  overflow-x: auto;
}
.table-content {
  width: 100%;
  margin-top: 15px;
}
thead th {
  padding: 10px;
}
td {
  padding: 10px;
}
tbody tr:hover {
  background: #d7dde5;
  color: #2c3e50;
}
/* Tables End */

@media screen and (max-width: 600px) {
  .table-content {
    width: 700px;
  }
}
</style>
