<template>
	<div>
		<div v-if="responseMessage">
			<div class="alert alert-danger" role="alert">
  				{{responseMessage}}
			</div>
		</div>
		<div class="row">
			
			<div class="d-card" v-if="user.is_admin">
				<div class="count_view blue  ">
					<div class="count_list">
						<h2>CAPITAL</h2>
						<p>
							{{
								formatNumber(
									comptePrincipal.capital || 0
								)
							}}
							FBU
						</p>
					</div>
					<div class="detail_list_icon">
						<i class="fas fa-money-bill-alt"></i>
					</div>
				</div>
			</div>
			<div class="d-card">
				<div class="count_view green">
					<div class="count_list">
						<h2>EPARGNE (Montant disponible)</h2>
						<p v-if="user.is_admin">
							{{
								formatNumber(comptePrincipal.somme_epargne || 0)
							}}
							FBU
						</p>
						<p v-if="!user.is_admin">
							{{
								formatNumber(comptePrincipal.depot_epargne || 0)
							}}
							FBU
						</p>
					</div>
					<div class="detail_list_icon">
						<i class="fas fa-money-bill-alt"></i>
					</div>
				</div>
			</div>

			<div class="d-card">
				<div class="count_view green">
					<div class="count_list">
						<h2>Caisse Sociale</h2>
						<p v-if="user.is_admin">
							{{
								formatNumber(
									comptePrincipal.somme_entraide || 0
								)
							}}
							FBU
						</p>
						<p v-if="!user.is_admin">
							{{
								formatNumber(
									comptePrincipal.depot_entraide || 0
								)
							}}
							FBU
						</p>
					</div>
					<div class="detail_list_icon">
						<i class="fas fa-money-bill-alt"></i>
					</div>
				</div>
			</div>
			<div class="d-card" v-if="user.is_admin">
				<div class="count_view red">
					<div class="count_list">
						<h2>Intérêt</h2>
						<p>
							{{ formatNumber(comptePrincipal.benefice || 0) }}
							FBU
						</p>
					</div>
					<div class="detail_list_icon">
						<i class="fas fa-money-bill-alt"></i>
					</div>
				</div>
			</div>

			<div class="d-card" v-if="user.is_admin">
				<div class="count_view red">
					<div class="count_list">
						<h2>DEPENSE D'EPARGNE</h2>
						<p>
							{{
								formatNumber(
									comptePrincipal.depenses_epargne || 0
								)
							}}
							FBU
						</p>
					</div>
					<div class="detail_list_icon">
						<i class="fas fa-money-bill-alt"></i>
					</div>
				</div>
			</div>
			<div class="d-card" v-if="user.is_admin">
				<div class="count_view red">
					<div class="count_list">
						<h2>Dépense sociale</h2>
						<p>
							{{
								formatNumber(
									comptePrincipal.depenses_entraide || 0
								)
							}}
							FBU
						</p>
					</div>
					<div class="detail_list_icon">
						<i class="fas fa-money-bill-alt"></i>
					</div>
				</div>
			</div>
		</div>
		

		<div class="butn-container" v-if="user.is_admin" >
		
			<button
				type="button"
				class="butn butn-close"
				@click="clotureExercice"
				v-if="comptePrincipal.is_active"
				
			>
				Cloturer l'exercice
			</button>

			<button
				type="button"
				class="butn butn-primary"
				@click="commencerExercice"
				v-if="!comptePrincipal.is_active"
			>
				Commencer l'exercice
			</button>


			<a href="https://csdteam.org/admin/dump" target="_blank" type="button" class="butn butn-info">Backup</a>
			<a href="https://csdteam.org/admin/load" target='_blank' type="button" class="butn butn-info-outline ">Load</a>
			
		</div>

		<div class="table-area" >

		
			<table class="table-content" v-if="user.is_admin">
				<thead>
					<tr>
						<th>EPARGNE</th>
						<th>Caisse Sociale</th>
						<th>Intérêt</th>
						<th>Total</th>
						<th>DATE</th>
					</tr>
				</thead>
				<tbody></tbody>
				<tr v-for="exercice in exercices" :key="exercice.id">
					<td>{{ formatNumber(exercice.somme_epargne) }}</td>
					<td>{{ formatNumber(exercice.somme_entraide) }}</td>
					<td>{{ formatNumber(exercice.benefice) }}</td>
					<td>{{ formatNumber(exercice.somme_epargne + exercice.somme_entraide + exercice.benefice  ) }}</td>
					<td>{{ exercice.created_at }}</td>
				</tr>
			</table>
		</div>
	</div>
</template>
<script>
import axios from "axios";

export default {
	name: "PlanetChart",
	data() {
		return {
			creditEl: 0,
			membreEl: 0,
			revele: false,
			responseMessage : ""
		};
	},
	mounted() {
		this.get_current();
		this.getExercice();
	},
	methods: {
		get_current() {
			axios
				.get(
					this.$store.state.url + "/exercice/get_current/",
					this.header
				)
				.then((res) => {
					this.$store.state.comptePrincipal = res.data;
				})
				.catch((err) => {
					console.error(err);
				});
		},
		get_myRetrait(){
			axios
				.get(
					this.$store.state.url + "/retrait/me/",
					this.header
				)
				.then((res) => {
					this.$store.state.mes_retrait = res.data;
				})
				.catch((err) => {
					console.error(err);
				});
		},
		getExercice() {
			axios
				.get(this.$store.state.url + "/exercice/", this.header)
				.then((res) => {
					//console.log(res)
					this.$store.state.exercices = res.data.results;
				})
				.catch((err) => {
					console.error(err);
				});
		},
		commencerExercice(){
			var a = prompt(
				"Entre votre nom pour la confirmation : " + this.user.fullname,
				""
			);
			
			if ( a && a.toLowerCase() == this.user.fullname.toLowerCase()) {
				axios
					.get(
						this.$store.state.url + "/exercice/demarrer/",
						this.header
					)
					.then((res) => {

						this.get_current();
						this.getExercice();
						console.log(res);
					})
					.catch((err) => {

						this.responseMessage = err.response.data.status;
						console.error(err.response.data.status);
					});
			} else {
				console.log("WAPI !!!!!!!!!!!!")
			}

		},
		clotureExercice() {
			// console.log(this.$store.state.user)
			var a = prompt(
				"Entre votre nom pour la confirmation : " + this.user.fullname,
				""
			);
			//alert(a.toLowerCase() == this.user.fullname.toLowerCase())
			if (a && a.toLowerCase() == this.user.fullname.toLowerCase()) {
				axios
					.get(
						this.$store.state.url + "/exercice/cloturer/",
						this.header
					)
					.then((res) => {

						this.get_current();
						this.getExercice();
						console.log(res);
					})
					.catch((err) => {
						//this.responseMessage = "Exercice déjà Cloturée"
						this.responseMessage = err.response.data.status;
						console.error(err.response.data.status);
					});
			} else {
			console.log("WAPI !!!!!!!!!!!!")
			}
		},
		backupDocument(){
			//alert("OK ")
			//https://csdteam.org/admin/dump
			window.location = "https://csdteam.org/admin/dump";
		},
		loadData(){
			//https://csdteam.org/admin/load
		}
	},
	computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
		user() {
			return this.$store.state.user;
		},
		comptePrincipal() {
			return this.$store.state.comptePrincipal;
		},
		exercices() {
			return this.$store.state.exercices;
		},
	},
};
</script>
<style scoped>
.row {
	display: flex;
	width: 90%;
	margin: 0 auto;
	flex-wrap: wrap;
	justify-content: space-between;
}
.d-card {
	width: 33%;
}

.count_view {
	height: 80px;
	margin-top: 7px;
	border-radius: 5px;
}
.count_view.red {
	background-color: rgba(255, 0, 0, 0.21);
}
.count_view.blue {
	background-color: rgba(0, 123, 255, 0.18);
}
.count_view.yellow {
	background-color: rgba(255, 255, 0, 0.23);
}
.count_view.orange {
	background-color: rgba(255, 165, 0, 0.19);
}
.count_view.green {
	background-color: rgba(49, 212, 133, 0.23);
}
.count_view .count_list {
	width: 79%;
	float: left;
}
.count_view .count_list h2 {
	font-size: 15px;
	padding-top: 15px;
	font-weight: 700;
	margin-bottom: 0px;
	text-align: center;
}
.count_view .count_list p {
	font-size: 25px;
	font-weight: 600;
	margin: 0px;
	text-align: center;
}
.count_view .detail_list_icon i {
	font-size: 30px;
	padding-top: 25px;
}
.count_view .detail_list_icon i.pink {
	color: #ff6565;
}
.count_view .detail_list_icon i.blue {
	color: #519cef;
}
.count_view .detail_list_icon i.yellow {
	color: #f7b743;
}
.count_view .detail_list_icon i.green {
	color: #25b725;
}

.butn-container {
  text-align: center;
  width: 90%;
  margin: 15px auto 0;
}
.butn {
  margin-top: 8px;
  margin-right: 8px;
  display: inline-block;	
}
.butn:last-child {
	margin-right: 0;
}
a {
	padding: 7px 15px;
}

.table-area {
	width: 90%;
	overflow-x: auto;
	margin: auto;
}
.table-content {
	width: 100%;
	margin-top: 15px;
}
thead th {
	padding: 10px;
}
td {
	padding: 10px;
}
tbody tr:hover {
	background: #d7dde5;
	color: #2c3e50;
}

@media screen and (max-width: 800px) {
  .row {
    width: 100%;
  }
  .d-card {
    width: 49%;
  }

  .butn-container {
    width: 100%;
  }

  .table-area {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
	.row {
		width: 100%;
		margin: 0;
	}
  .d-card {
    width: 100%;
  }
	.colon.p-2 {
		width: 100%;
	}
	.col-10 {
		margin: 0;
	}

  .butn {
    width: 100%;
  }

	.table-content {
		width: 700px;
	}
}
</style>
