<template>
    <div>
        <div>
		{{ capitalData}}
	
        <p>Caisse Sociale d’entraide et de Développement du personnel du CENAP « CSD »</p>
        <p>Nom&Prénom du demandeur</p>
        <p> {{ NumberToLetter(capitalData.capital_emprunter)}} Francs ({{ formatNumber(capitalData.capital_emprunter) }} BIF) ; octroyé le 1er/06/2021</p>

        <p>Tableau d’amortissement </p>
        <p>Date de début : <span> {{ capitalData.date_debut }}</span> <span>2021</span></p>
        <p>Montant du prêt : <span>96000 000</span> BIF</p>
        <p>Durée : <span>{{ 5 }}</span> mois</p>
        <p>Taux d'intérêt : <span>{{ 8 }}</span></p>
        <p>Mensualité:	{{  "Mensualite"}}	BIF/mois</p>
        <p>Coût des intérêts :	{{ '619 443,60' }}	BIF</p>

        </div>

        <div>
        <amortissement-component />
        </div>
        <div>

        <p>Montant à déduire (Montant restant du crédit en cours + la moitié des intérêts restant) : 4 847 881 BIF</p>
        <p>Montant à percevoir : 4 752 119 BIF</p>
        <p>Lu et approuvé ……………………………………………………… </p>
        <p>Nom&Prénom :  </p>
        <p>Pour la Commission Crédit : …………………………………………………………………………. </p>
        <p>Nom&Prénom : </p>
        </div>
    </div>
</template>

<script>
import AmortissementComponent from "../components/AmortissementComponent.vue";
export default {
    components: {
		AmortissementComponent,
	},
    data() {
        return {
            tableau_amortissement: [],
			revele: false,
        }
    },
    computed:{
        months(){
           return  [
                    'Janvier',
                    'Février',
                    'Mars',
                    'Avril',
                    'Mai',
                    'Juin',
                    'Juillet',
                    'Août',
                    'Septembre',
                    'Octobre',
                    'Novembre',
                    'Décembre'
                    ] 
        },
		capitalData(){
			return this.$store.state.capitalData
		}

    },
    methods: {

		caculerAmortissement(data) {

			let capital_emprunter = data.montant;
			let taux_interet_mensuel = data.taux / 100 / 12;
			let dure_pret = data.periode;
			let mensualite =
				capital_emprunter *
				(taux_interet_mensuel /
					(1 - (1 + taux_interet_mensuel) ** -dure_pret));
			let tableau_amortissement = [];

			let interet_mensuel = capital_emprunter * taux_interet_mensuel;
			let capital_rembourse = mensualite - interet_mensuel;
			let capital_restant = capital_emprunter - capital_rembourse;

			let paiement_mensuel = {
				capital_restant: capital_restant,
				interet_mensuel: interet_mensuel,
				capital_rembourse: capital_rembourse,
				mensualite: mensualite,
				echeance: 0,
			};

			tableau_amortissement.push(paiement_mensuel);

			for (let i = 1; i < dure_pret; i++) {
				interet_mensuel =
					tableau_amortissement[i - 1]["capital_restant"] *
					taux_interet_mensuel;
				capital_rembourse =
					mensualite -
					interet_mensuel +
					tableau_amortissement[i - 1]["capital_rembourse"];
				capital_restant =
					tableau_amortissement[i - 1]["capital_restant"] -
					(mensualite - interet_mensuel);

				paiement_mensuel = {
					capital_restant: capital_restant,
					interet_mensuel: interet_mensuel,
					capital_rembourse: capital_rembourse,
					mensualite: mensualite,
					echeance: i,
				};

				tableau_amortissement.push(paiement_mensuel);
			}

			this.tableau_amortissement = tableau_amortissement;
		},


        Unite( nombre ){
            var unite;
            switch( nombre ){
                case 0: unite = "zéro";		break;
                case 1: unite = "un";		break;
                case 2: unite = "deux";		break;
                case 3: unite = "trois"; 	break;
                case 4: unite = "quatre"; 	break;
                case 5: unite = "cinq"; 	break;
                case 6: unite = "six"; 		break;
                case 7: unite = "sept"; 	break;
                case 8: unite = "huit"; 	break;
                case 9: unite = "neuf"; 	break;
            }//fin switch
            return unite;
        },
     Dizaine( nombre ){
         var dizaine = ''
        switch( nombre ){
            case 10: dizaine = "dix"; break;
            case 11: dizaine = "onze"; break;
            case 12: dizaine = "douze"; break;
            case 13: dizaine = "treize"; break;
            case 14: dizaine = "quatorze"; break;
            case 15: dizaine = "quinze"; break;
            case 16: dizaine = "seize"; break;
            case 17: dizaine = "dix-sept"; break;
            case 18: dizaine = "dix-huit"; break;
            case 19: dizaine = "dix-neuf"; break;
            case 20: dizaine = "vingt"; break;
            case 30: dizaine = "trente"; break;
            case 40: dizaine = "quarante"; break;
            case 50: dizaine = "cinquante"; break;
            case 60: dizaine = "soixante"; break;
            case 70: dizaine = "soixante-dix"; break;
            case 80: dizaine = "quatre-vingt"; break;
            case 90: dizaine = "quatre-vingt-dix"; break;
	}//fin switch
	return dizaine;
    },
     NumberToLetter( nombre ){
	var i, j, n, quotient, reste, nb ;
	var ch
	var nombreLettre='';
	//__________________________________
	
	if(  nombre.toString().replace( / /gi, "" ).length > 15  )	return "dépassement de capacité";
	if(  isNaN(nombre.toString().replace( / /gi, "" ))  )		return "Nombre non valide";

	nb = parseFloat(nombre.toString().replace( / /gi, "" ));
	if(  Math.ceil(nb) != nb  )	return  "Nombre avec virgule non géré.";
	
	n = nb.toString().length;
	switch( n ){
		 case 1: nombreLettre = this.Unite(nb); break;
		 case 2: if(  nb > 19  ){
					   quotient = Math.floor(nb / 10);
					   reste = nb % 10;
					   if(  nb < 71 || (nb > 79 && nb < 91)  ){
							 if(  reste == 0  ) nombreLettre = this.Dizaine(quotient * 10);
							 if(  reste == 1  ) nombreLettre = this.Dizaine(quotient * 10) + "-et-" + this.Unite(reste);
							 if(  reste > 1   ) nombreLettre = this.Dizaine(quotient * 10) + "-" + this.Unite(reste);
					   }else nombreLettre = this.Dizaine((quotient - 1) * 10) + "-" + this.Dizaine(10 + reste);
				 }else nombreLettre = this.Dizaine(nb);
				 break;
		 case 3: quotient = Math.floor(nb / 100);
				 reste = nb % 100;
				 if(  quotient == 1 && reste == 0   ) nombreLettre = "cent";
				 if(  quotient == 1 && reste != 0   ) nombreLettre = "cent" + " " + this.NumberToLetter(reste);
				 if(  quotient > 1 && reste == 0    ) nombreLettre = this.Unite(quotient) + " cents";
				 if(  quotient > 1 && reste != 0    ) nombreLettre = this.Unite(quotient) + " cent " + this.NumberToLetter(reste);
				 break;
		 case 4 :  quotient = Math.floor(nb / 1000);
					  reste = nb - quotient * 1000;
					  if(  quotient == 1 && reste == 0   ) nombreLettre = "mille";
					  if(  quotient == 1 && reste != 0   ) nombreLettre = "mille" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille";
					  if(  quotient > 1 && reste != 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
					  break;
		 case 5 :  quotient = Math.floor(nb / 1000);
					  reste = nb - quotient * 1000;
					  if(  quotient == 1 && reste == 0   ) nombreLettre = "mille";
					  if(  quotient == 1 && reste != 0   ) nombreLettre = "mille" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille";
					  if(  quotient > 1 && reste != 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
					  break;
		 case 6 :  quotient = Math.floor(nb / 1000);
					  reste = nb - quotient * 1000;
					  if(  quotient == 1 && reste == 0   ) nombreLettre = "mille";
					  if(  quotient == 1 && reste != 0   ) nombreLettre = "mille" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille";
					  if(  quotient > 1 && reste != 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
					  break;
		 case 7: quotient = Math.floor(nb / 1000000);
					  reste = nb % 1000000;
					  if(  quotient == 1 && reste == 0  ) nombreLettre = "un million";
					  if(  quotient == 1 && reste != 0  ) nombreLettre = "un million" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions";
					  if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
					  break;  
		 case 8: quotient = Math.floor(nb / 1000000);
					  reste = nb % 1000000;
					  if(  quotient == 1 && reste == 0  ) nombreLettre = "un million";
					  if(  quotient == 1 && reste != 0  ) nombreLettre = "un million" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions";
					  if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
					  break;  
		 case 9: quotient = Math.floor(nb / 1000000);
					  reste = nb % 1000000;
					  if(  quotient == 1 && reste == 0  ) nombreLettre = "un million";
					  if(  quotient == 1 && reste != 0  ) nombreLettre = "un million" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions";
					  if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
					  break;  
		 case 10: quotient = Math.floor(nb / 1000000000);
						reste = nb - quotient * 1000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un milliard";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un milliard" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
					    break;	
		 case 11: quotient = Math.floor(nb / 1000000000);
						reste = nb - quotient * 1000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un milliard";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un milliard" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
					    break;	
		 case 12: quotient = Math.floor(nb / 1000000000);
						reste = nb - quotient * 1000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un milliard";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un milliard" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
					    break;	
		 case 13: quotient = Math.floor(nb / 1000000000000);
						reste = nb - quotient * 1000000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un billion";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un billion" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
					    break; 	
		 case 14: quotient = Math.floor(nb / 1000000000000);
						reste = nb - quotient * 1000000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un billion";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un billion" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
					    break; 	
		 case 15: quotient = Math.floor(nb / 1000000000000);
						reste = nb - quotient * 1000000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un billion";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un billion" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
					    break; 	
	 }//fin switch
	 /*respect de l'accord de quatre-vingt*/
	 if(  nombreLettre.substr(nombreLettre.length-"quatre-vingt".length,"quatre-vingt".length) == "quatre-vingt"  ) nombreLettre = nombreLettre + "s";
	 
	 return nombreLettre;
},
        
        
    },
}
</script>