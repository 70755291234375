<template>
    <div>
    <div> 
    <button @click="printMember" class="btn btn-primary mr">Imprimer</button>
    <button @click="exportToExecel" class="btn btn-primary">Expoter vers Excel</button>
    </div>
      
      <div id="print" class="">

				<div class="header_print">
					<div>
					<h2>CAISSE SOCIAL</h2>
					</div>

					<div> 
					<h2> {{new Date().toDateString()}} </h2>
					</div>
				
				 </div>
				<table class="">
					<thead>
						<tr>
                        <th> NUMERO </th>
						<th> NOM </th>
						<th> PRENOM </th>
						<th> EPARGNE </th>
						<th> SOCIAL</th>
						<th> CREDIT</th>
						<th> TOTAL</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(membre, index) in liste_membres" :key="membre.id">
                            <td>{{index + 1}}</td>
							<td>
							{{membre.user.first_name}}
							</td>
							<td>
							{{membre.user.last_name}}
							</td>
							<td>
							<b>{{money(Math.ceil(membre.somme_epargne))}}</b>
							</td>
							<td>
							
                            <b>{{money(Math.ceil(membre.somme_entraide))}}</b>
							</td>

							<td>
							
                            <b>{{ money(Math.ceil(membre.credit))}}</b>
							</td>

                            <td>
                            <b>{{ money(Math.ceil(membre.somme_entraide + membre.somme_epargne + membre.credit))}}</b>
                            </td>
						</tr>
                        <tr>
                        <td colspan="3"> TOTAL</td>
                        
                        <td>
                        <b> {{money(Math.ceil(depot_epargne.epargnes))}} </b>
                        </td>
                        <td> <b>{{depot_epargne.entraide }} </b></td>
                        <td> <b>{{Math.ceil(depot_epargne.credit) }} </b></td>
                        <td> <b>{{money(Math.ceil(depot_epargne.epargnes + depot_epargne.entraide + depot_epargne.credit)) }} </b></td>
                     
                        </tr>
					</tbody>
				</table>
			</div>
        
    </div>
</template>

<script>
import axios from "axios"

export default {
    data() {
        return {
            
        }
    },
    mounted() {

        axios.get(this.$store.state.url+"/compte/rapport/",this.header)
			.then(res => {
                this.$store.state.paiment_mensuels = res.data
			})
		.catch(err => {
				console.error(err); 
		})
        
    },
    methods: {
        fetchData(){
            axios.get(this.state.url+"",params)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.error(err); 
            })
        },

        printMember(){
			//let liste_membres = []
		
			const prtHtml = document.getElementById('print').innerHTML;
			const WinPrint = window.open('', '', `width=${screen.width},height=${screen.height},resizable=yes,fullscreen=yes`);

			WinPrint.document.write(`<!DOCTYPE html>
			<html>
			<head>
			<style>
			table{
				width : 100%;
				border-collapse : collapse;
			}

			table,tr,td,th{
				border: 1px solid #000;
			}
			.header_print{
				display: flex;
				justify-content: space-between;
			}

			</style>
				
			</head>
			<body>
				${prtHtml}
			</body>
			</html>`);

			WinPrint.document.close();
			WinPrint.focus();
			WinPrint.print();
			setTimeout(() => {
				WinPrint.close();
			}, 1000);
		},
		exportToExecel(){
			this.exportTableToExcel('print','fiche_du_'+(new Date().getMonth()+1)+"_"+new Date().getFullYear())
		},
		exportTableToExcel(tableId, filename) {
		let dataType = 'application/vnd.ms-excel';
		let extension = '.xls';

		let base64 = function(s) {
			return window.btoa(unescape(encodeURIComponent(s)))
		};

		let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
		let render = function(template, content) {
			return template.replace(/{(\w+)}/g, function(m, p) { return content[p]; });
		};

		let tableElement = document.getElementById(tableId);

		let tableExcel = render(template, {
			worksheet: filename,
			table: tableElement.innerHTML
		});

		filename = filename + extension;

		if (navigator.msSaveOrOpenBlob)
		{
			let blob = new Blob(
				[ '\ufeff', tableExcel ],
				{ type: dataType }
				);

			navigator.msSaveOrOpenBlob(blob, filename);
		} else {
			let downloadLink = document.createElement("a");

			document.body.appendChild(downloadLink);

			downloadLink.href = 'data:' + dataType + ';base64,' + base64(tableExcel);

			downloadLink.download = filename;

			downloadLink.click();
		}
	}


    },
    computed:{
        liste_membres(){
            return this.$store.state.paiment_mensuels
        },
        depot_epargne(){
            let epargnes = 0;
            let entraide = 0;
            let credit = 0;
            let total = 0;
            for(let i =0; i < this.liste_membres.length; i++){
                epargnes += this.liste_membres[i].somme_epargne
                entraide += this.liste_membres[i].somme_entraide
                credit += this.liste_membres[i].credit
                total += epargnes  + entraide + credit
            }
            
            return { epargnes : epargnes, entraide : entraide,credit: credit, total : total}
        },
        header(){
            return{
				headers :{
					"Authorization" : `Bearer ${this.$store.state.user.access}`
				}
			}
        },
    }
    
}
</script>