<template>
	<div class="table-container">
		<button @click="getActiveUser" class="butn-sm butn-primary-outline">
			Liste des membres actifs
		</button>
		<button @click="getDesactiveUser" class="butn-sm butn-close-outline">
			Liste des membres Désactivés
		</button>
		<div class="table-area">
			<table class="table-content">
				<thead class="text-center">
					<tr>
						<th>NOM ET PRENOM</th>
						<th>COMPTE</th>
						<th>TELEPHONE</th>
						<th>EPARGNE</th>
						<th>ENTRAIDE</th>
						<th>TOTAL EPARGNE </th>
						<th>TOTAL SOCIAL</th>

						<th>ACTION</th>
					</tr>
				</thead>
				<tbody>
					<tr></tr>
					<tr v-for="membre in members" :key="membre.id">
						<td>
							{{ membre.user.first_name }}
							{{ membre.user.last_name }}
						</td>
						<td>{{ membre.num_compte }}</td>
						<td>{{ membre.telephone }}</td>
						<td>{{ formatNumber(membre.somme_epargne) }}</td>
						<td>{{ formatNumber(membre.somme_entraide) }}</td>
						<td>
							{{formatNumber(membre.total_epargne)}}
						</td>
						<td>
							{{ formatNumber(membre.total_entraide)}}
							</td>
						<td class="d-flex">
							<button
								v-if="
									user.is_admin  &&
										membre.user.is_active
								"
								class="butn-sm butn-info mr-2"
								@click="modifierMembre(membre)"
							>
								Modifier
							</button>
							<button
								v-if="user.is_admin && membre.user.is_active"
								class="butn-sm butn-sm-s"
								@click="gestionnaireMembre(membre)"
							>
								<span
									v-if="
										membre.user.is_superuser &&(membre.user.id !=  user.id) &&
											membre.user.is_active
									"
									class="butn-sm butn-close-outline"
									>Destituer
								</span>
								<span
									v-if="
										!membre.user.is_superuser &&
											membre.user.is_active
									"
									class="butn-sm butn-info-outline"
									>Gestionnaire</span
								>
							</button>
							<button
								v-if="user.is_admin && membre.user.is_active"
								class="butn-sm butn-close"
								@click="deleteMember(membre)"
							>
								Désactiver
							</button>
							<button
								v-else
								class="butn-sm butn-primary"
								@click="activerMember(membre)"
							>
								Activer
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="pagination" v-if="page_counts > 1">
			<button
				v-for="no in page_counts"
				:class="{ active: $route.params['current_page'] == no }"
				:key="no"
				@click="goto(no)"
			>
				{{ no }}
			</button>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import ModaleMember from "./ModaleMember.vue";
export default {
	components: { ModaleMember },
	props: ["revele", "toggleForm", "membre", "searchName"],
	data() {
		return {
			members: this.$store.state.members,
			page_counts: 0,
		};
	},
	watch: {
		"$store.state.members"(new_val) {
			this.members = new_val;
		},
		"$store.state.keyword"(new_val) {
			let member = "";
			this.members = this.$store.state.members.filter((x) => {
				member = JSON.stringify(x);
				return member
					.toLocaleLowerCase()
					.includes(new_val.toLocaleLowerCase());
			});
		},
		searchName() {
			// this.$store.members =  this.members.filter(membre => membre.user.first_name.includes(this.searchName))
		},
	},
	computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
		nombrePage() {
			return Math.ceil(this.members.count / this.members.results.length);
		},
		user() {
			return this.$store.state.user;
		},
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		fetchData() {
			axios
				.get(
					this.$store.state.url +
						"/compte/?page=" +
						this.$route.params["current_page"],
					this.header
				)
				.then((res) => {
					console.log(res);
					this.$store.state.members = res.data.results;
					//this.page_counts = Math.ceil(res.data.count/this.$store.state.page_count)
					this.page_counts = Math.ceil(
						res.data.count / this.$store.state.page_count
					);
				}).then((e) =>{
					this.getActiveUser();
				}
				)
				.catch((err) => {
					console.error(err);
				});
		},
		getActiveUser() {
			this.members = this.$store.state.members.filter(
				(item) => item.user.is_active
			);
		},
		getDesactiveUser() {
			this.members = this.$store.state.members.filter(
				(item) => !item.user.is_active
			);
		},
		gestionnaireMembre(m) {
			const current_droit = !m.user.is_superuser;
			let number = Math.floor(Math.random() * 99999);
			var givenNumber = prompt("Tapez : " + number + " Pour confirmer");
			const data = {
				user: {
					is_superuser: current_droit,
				},
			};
			if (givenNumber == number) {
				axios
					.patch(
						this.$store.state.url + "/compte/" + m.id + "/",
						data,
						this.header
					)
					.then((res) => {
						if (current_droit) {
							alert("GESTIONNAIRE");
						} else {
							alert("DESTITUER");
						}

						this.fetchData();
					})
					.catch((err) => {
						alert("ERROR");
					});
			}
		},
		modifierMembre(mebre_recu) {
			this.$store.state.selectedMembre = mebre_recu;
			this.$emit("modifierMembre", {});
		},
		deleteMember(membre) {
			console.log(membre.id)
			const response = confirm("Are you sure ? ");

			if (response) {
				axios
					.delete(
						this.$store.state.url + "/compte/" + membre.id + "/",
						this.header
					)
					.then((res) => {
						console.log(res);
						this.$store.state.members = this.$store.state.members.filter(
							(c) => c.id != membre.id
						);
						alert("REUSSI");
					}).then((e) =>{
						this.fetchData();
					}
					)
					.catch((err) => {
						alert("ERROR");
					});
			}
		},
		activerMember(membre) {
			const response = confirm("Are you sure ? ");
			if (response) {
				membre.user.is_active = true
				axios
					.patch(
						this.$store.state.url + "/compte/" + membre.id + "/",
						membre,
						this.header
					)
					.then((res) => {
						this.fetchData();
					})
					.catch((err) => {
						alert("ERROR");
					});
			}
		},
		goto(no) {
			this.$router.push("/membre/" + no);
			this.fetchData();
		},
	},
};
</script>

<style scoped>
.butn-sm {
	margin-right: 10px;
}
.butn-sm-s {
	padding: 0;
	margin: 0;
}

/* Tables */
.table-area {
	width: 100%;
	overflow-x: auto;
}
.table-content {
	width: 100%;
	margin-top: 15px;
}
th {
	padding: 10px;
}
td {
	padding: 10px;
}
tbody tr:hover {
	background: #d7dde5;
	color: #2c3e50;
}
/* Tables End */

@media screen and (max-width: 600px) {
	.table-content {
		width: 700px;
	}
	.butn-sm {
		margin-bottom: 7px;
		font-size: 15px;
	}

	tbody .butn-sm {
		margin-bottom: 5px;
		margin-right: 5px;
		margin-top: 5px;
		font-size: 13px;
	}
	tbody .butn-sm:first-child {
		margin-top: 0;
	}
	tbody .butn-sm:last-child {
		margin-bottom: 0;
	}

	.butn-sm-s {
		padding: 0;
		margin: 0;
	}
}
</style>
