<template>
	<div>
    
		<div>
		<button @click="imprimerDepot" class="butn butn-primary"> IMPRIMER</button>
		</div>


		<div class="table-area" id="print">
			<table class="table-content">
				<thead>
					<tr>
						<th>NOM ET PRENOM</th>
						<th>SOMME</th>
						<th>TYPE</th>
						<th>DATE</th>
						<th class="no_print">ACTION</th>
					</tr>
				</thead>
				<tbody>
                
                <tr v-for="depot in detailsDepots" :key="depot.id">
                  <td>{{ depot.compte.user.first_name }} {{ depot.compte.user.last_name }}</td>
                  <td>{{ depot.somme }} </td>
                  <td>{{ depot.details }} </td>
                  <td>{{ depot.date }} </td>
                  <td class="no_print"> <button type="button" class="butn-sm butn-close" @click="deleteDetail(depot.id)"> Annuler </button></td>

                </tr>
					
				</tbody>
			</table>
		</div>

    
	</div>
</template>

<script>
import axios from "axios";

export default {
    data(){
        return{

        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData(){
        const id = this.$route.params.id;  
        axios.get(this.$store.state.url+"/depot/"+id+"/",this.header)
        .then(res => {
            this.$store.state.detailsDepots = res.data.depots;
            console.log(res.data)
        })
        .catch(err => {
            console.error(err); 
        })

        },
        deleteDetail(id){

            if(confirm("êtes-vous sûr ? ")){
             axios.delete(this.$store.state.url+"/details/"+id+"/",this.header)
            .then(res => {
                this.fetchData();
            
            })
            .catch(err => {
                console.error(err); 
            })

            }
            
        },
        imprimerDepot(){
           
			//let liste_membres = []
		
			const prtHtml = document.getElementById('print').innerHTML;
			const WinPrint = window.open('', '', `width=${screen.width},height=${screen.height},resizable=yes,fullscreen=yes`);

			WinPrint.document.write(`<!DOCTYPE html>
			<html>
			<head>
			<style>
			table{
				width : 100%;
				border-collapse : collapse;
			}

			table,tr,td,th{
				border: 1px solid #000;
			}
			.header_print{
				display: flex;
				justify-content: space-between;
			}
            .no_print {
               display: none;
           }

			</style>
				
			</head>
			<body>
				${prtHtml}
			</body>
			</html>`);

			WinPrint.document.close();
			WinPrint.focus();
            WinPrint.print();
			setTimeout(() => {
				WinPrint.close();
			}, 1000);
        }
    },
    computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
		detailsDepots() {
			return this.$store.state.detailsDepots;
		},
	},
};
</script>
<style scoped>
/* Tables */
.table-area {
	width: 100%;
	overflow-x: auto;
}
.table-content {
	width: 100%;
	margin-top: 15px;
}
thead th {
	padding: 10px;
}
td {
	padding: 10px;
}
tbody tr:hover {
	background: #d7dde5;
	color: #2c3e50;
}

@media print {
    .no_print {
        display: none;
    }
    
}
/* Tables End */
</style>
