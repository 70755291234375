<template>
	<div>
		<div>
			<button class="butn butn-info" @click="genererRetrait">
				Générer les depôts
			</button>
			{{ response }}
		</div>

		<depot-liste />
	</div>
</template>

<script>
import axios from "axios";
import DepotListe from "../components/DepotListe.vue";
export default {
	components: { DepotListe },
	data() {
		return {
			response: "",
		};
	},
	methods: {
		genererRetrait() {
			axios
				.get(this.$store.state.url + "/depot/generate/", this.header)
				.then((res) => {
					this.response = "opération terminé";
				})
				.catch((err) => {
					// console.error(err);
					this.response = "already generated";
				});
		},
	},

	computed: {
		header() {
			return {
				headers: {
					Authorization: `Bearer ${this.$store.state.user.access}`,
				},
			};
		},
	},
};
</script>

<style scoped></style>
