<template>
	<div>
		<button @click="imprimerTable"> <i class="fa fa-print"></i> Imprimer</button>
		<div class="table-area" id="print">
            <div class="header_main">
            <h3 class="header">Caisse Sociale d'entraide et de Développement du personnel du CENAP "CSD"</h3>
            <h4>Nom & Prénom du demandeur : {{fullname}}</h4>
            <h3>Crédit de {{NumberToLetter(credit.montant)}} Francs ({{formatNumber(credit.montant)}} BIF) ; octroyé  {{date_debut}}</h3>
            <h3 class="table-amort">Tableau d'amortissement</h3>

            </div>

			<table class="table-content">
            <tbody class="mini-table">
               
                <tr>
                    <td class="start">Date de début : </td>
                    <td class="white">{{months[credit.date_debut ]  }}</td>
                    <td class="white">{{formatNumber(credit.montant)}}</td>
                </tr>
               
                <tr>
                    <td class="start">Montant du prêt : </td>
                    <td class="white">{{formatNumber(credit.montant)}}</td>
                    <td>BIF</td>
                </tr>
    
                <tr>
                    <td class="start">Durée : </td>
                    <td class="white">{{credit.periode}}</td>
                    <td>mois</td>
                </tr>
                
                <tr>
                    <td class="start">Taux d'intérêt : </td>
                    <td class="white">{{credit.taux}}</td>
                    <td>%</td>
                </tr>
                <tr>
                    <td class="start">Mensualité : </td>
                    <td>{{ formatNumber(tableau_amortissement[0].mensualite) }}</td>
                    <td>BIF/mois</td>
                </tr>
                
            </tbody>

				<tbody>
				
					<tr>
						<th>N°</th>
						<th>Date</th>
						<th>Capital restant dû</th>
						<th>Capital remboursé</th>
						<th>Intérêts</th>
						    <th>Cumul des intérêts</th>
						<th>Mensualité</th>
					</tr>
				</tbody>
				<tbody>
					<tr
						v-for="element in tableau_amortissement"
						:key="element.id"
					>
						<td>{{ element.echeance + 1 }} </td>
						<td>{{ months[element.date_debut % 12 ]  }} - {{ element.annee}} </td>
						<td>{{ formatNumber(element.capital_restant) }}</td>
						<td>{{ formatNumber(element.capital_rembourse) }}</td>
						<td>{{ formatNumber(element.interet_mensuel) }}</td>
						<td>{{ formatNumber(element.cumule_interet) }}</td>
						<td>{{ formatNumber(element.mensualite) }}</td>
					</tr>
				</tbody>
			</table>

           <div style="text-align: left;">
        

        <p>Lu et approuvé</p>
        <p>.............................................................</p>
        <p>Nom & Prénom : </p>

        <p>Pour la Commision Credit :</p>
        <p>...................................................................................................................</p>
        <p>Nom & Prénom : </p>

           </div>
		</div>
	</div>
</template>

<script>

export default {
	components: {  },
	data() {
		return {
			tableau_amortissement: [],
			revele: false,
            fullname : "",
            date_debut: {

            },
            credit : {

            }
		};
	},
	computed:{

		 months(){
           return  [
                    'Janvier',
                    'Février',
                    'Mars',
                    'Avril',
                    'Mai',
                    'Juin',
                    'Juillet',
                    'Août',
                    'Septembre',
                    'Octobre',
                    'Novembre',
                    'Décembre'
                    ] 
        }
	},
    mounted() {
        let element = this.$route.params.current_client
       
        element = element.split("*")
        
         this.fullname = element[1] + " " + element[2] 
         const year = element[4].split("-")

         this.date_debut = element[4]
          
        const data ={
                montant : element[3],
                periode : element[5],
                taux: element[6],
                date_debut : Number(year[1]),
                annee :year[0]
                } 
        this.credit = data
        this.caculerAmortissement(data)
    },

	methods: {

        imprimerTable(){
             const prtHtml = document.getElementById('print').innerHTML;
			const WinPrint = window.open('', '', `width=${screen.width},height=${screen.height},resizable=yes,fullscreen=yes`);

            WinPrint.document.write(`<!DOCTYPE html>
			<html>
			<head>
			<style>
			table{
				width : 100%;
				border-collapse : collapse;
			}

			table,tr,td,th{
				border: 1px solid #000;
			}
			.header_print{
				display: flex;
				justify-content: space-between;
			}

			</style>
				
			</head>
			<body>
				${prtHtml}
			</body>
			</html>`);

            WinPrint.document.close();
			WinPrint.focus();
            WinPrint.print();
			setTimeout(() => {
				WinPrint.close();
			}, 1000);

        },
		toggleForm() {
			this.revele = !this.revele;
		}, 
		caculerAmortissement(data) {
			
			let capital_emprunter = data.montant;
			let taux_interet_mensuel = data.taux / 100 / 12;
			let dure_pret = data.periode;
			let mensualite =
				capital_emprunter *
				(taux_interet_mensuel /
					(1 - (1 + taux_interet_mensuel) ** -dure_pret));

			if(taux_interet_mensuel == 0){
				console.log("ZEROOOOOO")
				mensualite = capital_emprunter / dure_pret
			}

			let tableau_amortissement = [];

			let interet_mensuel = capital_emprunter * taux_interet_mensuel;
			let capital_rembourse = mensualite - interet_mensuel;
			let capital_restant = capital_emprunter - capital_rembourse;
			let cumule_interet = interet_mensuel
			let date_debut = data.date_debut
			let annee = data.annee

			let paiement_mensuel = {
				date_debut : data.date_debut,
				annee : data.annee,
				capital_restant: capital_restant,
				interet_mensuel: interet_mensuel,
				capital_rembourse: capital_rembourse,
				mensualite: mensualite,
				echeance: 0,
				cumule_interet : cumule_interet,
				capital_emprunter : capital_emprunter,
				dure_pret : dure_pret,
				taux_interet_mensuel : taux_interet_mensuel,
			};

			this.$store.state.capitalData = paiement_mensuel

			tableau_amortissement.push(paiement_mensuel);

			for (let i = 1; i < dure_pret; i++) {
				interet_mensuel =
					tableau_amortissement[i - 1]["capital_restant"] *
					taux_interet_mensuel;
				capital_rembourse =
					mensualite -
					interet_mensuel +
					tableau_amortissement[i - 1]["capital_rembourse"];
				capital_restant =
					tableau_amortissement[i - 1]["capital_restant"] -
					(mensualite - interet_mensuel);
				cumule_interet += interet_mensuel
				date_debut = date_debut + 1;
				annee = tableau_amortissement[i - 1]["annee"]

				if(date_debut %12 == 0){
					console.log(tableau_amortissement[i - 1]["annee"])
					annee = (tableau_amortissement[i - 1]["annee"] *1)  + 1
				}

				paiement_mensuel = {
					capital_restant: capital_restant,
					interet_mensuel: interet_mensuel,
					capital_rembourse: capital_rembourse,
					mensualite: mensualite,
					echeance: i,
					annee : annee,
					cumule_interet : cumule_interet,
					date_debut : date_debut,
				};

				tableau_amortissement.push(paiement_mensuel);
			}

			this.tableau_amortissement = tableau_amortissement;
		},

            Unite( nombre ){
            var unite;
            switch( nombre ){
                case 0: unite = "zéro";		break;
                case 1: unite = "un";		break;
                case 2: unite = "deux";		break;
                case 3: unite = "trois"; 	break;
                case 4: unite = "quatre"; 	break;
                case 5: unite = "cinq"; 	break;
                case 6: unite = "six"; 		break;
                case 7: unite = "sept"; 	break;
                case 8: unite = "huit"; 	break;
                case 9: unite = "neuf"; 	break;
            }//fin switch
            return unite;
        },
     Dizaine( nombre ){
         var dizaine = ''
        switch( nombre ){
            case 10: dizaine = "dix"; break;
            case 11: dizaine = "onze"; break;
            case 12: dizaine = "douze"; break;
            case 13: dizaine = "treize"; break;
            case 14: dizaine = "quatorze"; break;
            case 15: dizaine = "quinze"; break;
            case 16: dizaine = "seize"; break;
            case 17: dizaine = "dix-sept"; break;
            case 18: dizaine = "dix-huit"; break;
            case 19: dizaine = "dix-neuf"; break;
            case 20: dizaine = "vingt"; break;
            case 30: dizaine = "trente"; break;
            case 40: dizaine = "quarante"; break;
            case 50: dizaine = "cinquante"; break;
            case 60: dizaine = "soixante"; break;
            case 70: dizaine = "soixante-dix"; break;
            case 80: dizaine = "quatre-vingt"; break;
            case 90: dizaine = "quatre-vingt-dix"; break;
	}//fin switch
	return dizaine;
    },
     NumberToLetter( nombre ){
	var i, j, n, quotient, reste, nb ;
	var ch
	var nombreLettre='';
	//__________________________________
	
	if(  nombre.toString().replace( / /gi, "" ).length > 15  )	return "dépassement de capacité";
	if(  isNaN(nombre.toString().replace( / /gi, "" ))  )		return "Nombre non valide";

	nb = parseFloat(nombre.toString().replace( / /gi, "" ));
	if(  Math.ceil(nb) != nb  )	return  "Nombre avec virgule non géré.";
	
	n = nb.toString().length;
	switch( n ){
		 case 1: nombreLettre = this.Unite(nb); break;
		 case 2: if(  nb > 19  ){
					   quotient = Math.floor(nb / 10);
					   reste = nb % 10;
					   if(  nb < 71 || (nb > 79 && nb < 91)  ){
							 if(  reste == 0  ) nombreLettre = this.Dizaine(quotient * 10);
							 if(  reste == 1  ) nombreLettre = this.Dizaine(quotient * 10) + "-et-" + this.Unite(reste);
							 if(  reste > 1   ) nombreLettre = this.Dizaine(quotient * 10) + "-" + this.Unite(reste);
					   }else nombreLettre = this.Dizaine((quotient - 1) * 10) + "-" + this.Dizaine(10 + reste);
				 }else nombreLettre = this.Dizaine(nb);
				 break;
		 case 3: quotient = Math.floor(nb / 100);
				 reste = nb % 100;
				 if(  quotient == 1 && reste == 0   ) nombreLettre = "cent";
				 if(  quotient == 1 && reste != 0   ) nombreLettre = "cent" + " " + this.NumberToLetter(reste);
				 if(  quotient > 1 && reste == 0    ) nombreLettre = this.Unite(quotient) + " cents";
				 if(  quotient > 1 && reste != 0    ) nombreLettre = this.Unite(quotient) + " cent " + this.NumberToLetter(reste);
				 break;
		 case 4 :  quotient = Math.floor(nb / 1000);
					  reste = nb - quotient * 1000;
					  if(  quotient == 1 && reste == 0   ) nombreLettre = "mille";
					  if(  quotient == 1 && reste != 0   ) nombreLettre = "mille" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille";
					  if(  quotient > 1 && reste != 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
					  break;
		 case 5 :  quotient = Math.floor(nb / 1000);
					  reste = nb - quotient * 1000;
					  if(  quotient == 1 && reste == 0   ) nombreLettre = "mille";
					  if(  quotient == 1 && reste != 0   ) nombreLettre = "mille" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille";
					  if(  quotient > 1 && reste != 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
					  break;
		 case 6 :  quotient = Math.floor(nb / 1000);
					  reste = nb - quotient * 1000;
					  if(  quotient == 1 && reste == 0   ) nombreLettre = "mille";
					  if(  quotient == 1 && reste != 0   ) nombreLettre = "mille" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille";
					  if(  quotient > 1 && reste != 0    ) nombreLettre = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
					  break;
		 case 7: quotient = Math.floor(nb / 1000000);
					  reste = nb % 1000000;
					  if(  quotient == 1 && reste == 0  ) nombreLettre = "un million";
					  if(  quotient == 1 && reste != 0  ) nombreLettre = "un million" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions";
					  if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
					  break;  
		 case 8: quotient = Math.floor(nb / 1000000);
					  reste = nb % 1000000;
					  if(  quotient == 1 && reste == 0  ) nombreLettre = "un million";
					  if(  quotient == 1 && reste != 0  ) nombreLettre = "un million" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions";
					  if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
					  break;  
		 case 9: quotient = Math.floor(nb / 1000000);
					  reste = nb % 1000000;
					  if(  quotient == 1 && reste == 0  ) nombreLettre = "un million";
					  if(  quotient == 1 && reste != 0  ) nombreLettre = "un million" + " " + this.NumberToLetter(reste);
					  if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions";
					  if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
					  break;  
		 case 10: quotient = Math.floor(nb / 1000000000);
						reste = nb - quotient * 1000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un milliard";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un milliard" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
					    break;	
		 case 11: quotient = Math.floor(nb / 1000000000);
						reste = nb - quotient * 1000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un milliard";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un milliard" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
					    break;	
		 case 12: quotient = Math.floor(nb / 1000000000);
						reste = nb - quotient * 1000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un milliard";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un milliard" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
					    break;	
		 case 13: quotient = Math.floor(nb / 1000000000000);
						reste = nb - quotient * 1000000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un billion";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un billion" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
					    break; 	
		 case 14: quotient = Math.floor(nb / 1000000000000);
						reste = nb - quotient * 1000000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un billion";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un billion" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
					    break; 	
		 case 15: quotient = Math.floor(nb / 1000000000000);
						reste = nb - quotient * 1000000000000;
						if(  quotient == 1 && reste == 0  ) nombreLettre = "un billion";
						if(  quotient == 1 && reste != 0  ) nombreLettre = "un billion" + " " + this.NumberToLetter(reste);
						if(  quotient > 1 && reste == 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions";
						if(  quotient > 1 && reste != 0   ) nombreLettre = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
					    break; 	
	 }//fin switch
	 /*respect de l'accord de quatre-vingt*/
	 if(  nombreLettre.substr(nombreLettre.length-"quatre-vingt".length,"quatre-vingt".length) == "quatre-vingt"  ) nombreLettre = nombreLettre + "s";
	 
	 return nombreLettre;
},
	},
};
</script>

<style scoped>
/* Tables */

.header_main{
    text-align : left;
}
.table-area {
	width: 100%;
	overflow-x: auto;
}
.table-content {
	width: 100%;
	margin-top: 15px;
}
th {
	padding: 10px;
}
td {
	padding: 10px;
}
tbody tr:hover {
	background: #d7dde5;
	color: #2c3e50;
}
/* Tables End */

@media screen and (max-width: 600px) {
   .butn {
      padding: 5px 15px;
      font-size: 14px;
   }
	.table-content {
		width: 700px;
	}
}

 body {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    .main {
        width: 70%;
        margin: 0 auto;
    }
    .header {
        text-align: center;
    }
    .table-amort {
        text-align: center;
    }

    table {
        width: 100%;
    }
    table, tr, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    th {
        padding: 8px 20px;
        background: #a6ffff;
    }
    td {
        padding:  5px auto;
        text-align: center;
    }
    .start {
        text-align: right;
    }

    .mini-table tr {
        height: 20px;
        background: #a6ffff;

    }
    .mini-table {
        min-width: 500px;
    }
    .white {
        background: #fff;
    }
</style>
