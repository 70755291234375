<template>
<section class="wrapper">
  <div class="row card_row">
    <h1 class="column h6 color_label">Some Category</h1>
    <div class="column half_whole">
      <article class="card box_panel">
        <label class="card_label">
          Dashboard Item
        </label>
        <section class="card_body">
          <div class="graph">
            <div class="knob_data">89<span class="txt_smaller">%</span></div>
            <svg class="graph_media" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 266.4 266.4">
              <g>
                <path class="st0" d="M130.5,32.2V0c-53.3,1.1-99,33.5-119.3,79.6l30,11.8C56.7,57.3,90.7,33.3,130.5,32.2z"/>
                <path class="st1" d="M133.2,0c-0.9,0-1.8,0-2.7,0v32.2c0.9,0,1.8,0,2.7,0c55.8,0,101,45.2,101,101s-45.2,101-101,101
                  s-101-45.2-101-101c0-14.9,3.2-29,9-41.7l-30-11.8C4,96,0,114.1,0,133.2c0,73.6,59.6,133.2,133.2,133.2s133.2-59.6,133.2-133.2
                  S206.7,0,133.2,0z"/>
              </g>
            </svg>
          </div>
</section>
        <section class="card_more">
          <div class="color_label card_more_content card_division">
            Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
          </div>
          <a class="icon icon_after card_more_button button_soft"></a>
        </section>
        <section class="stats stats_row">
            <div class="stats_item half_whole small_whole">
              <div class="txt_faded">
                <label class="txt_label space_n_b">
                  Goal
                </label>
                <div class="txt_serif stats_item_number txt_success">
                  85<span class="txt_smaller">%</span>
                </div>
              </div>
            </div>
            <div class="stats_item half_whole">
              <div class="txt_faded">
                <label class="txt_label space_n_b">
                  Red Line
                </label>
                <div class="txt_serif stats_item_number txt_error">
                  80<span class="txt_smaller">.2%</span>
                </div>
              </div>
            </div>
        </section>
      </article>
    </div>
    <div class="column half_whole">
      <article class="card box_panel">
        <label class="card_label">
          Dashboard Item
        </label>
        <section class="card_body">
          <div class="graph">
            <div class="txt_warn graph_data txt_serif">25,000</div>
          </div>
        </section>
        <section class="card_more">
          <div class="color_label card_more_content card_division">
            Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
          </div>
          <a class="icon icon_after card_more_button button_soft"></a>
        </section>
        <section class="stats stats_row">
            <div class="stats_item half_whole">
              <div class="txt_faded">
                <label class="txt_label space_n_b">
                  Goal
                </label>
                <div class="txt_serif stats_item_number txt_success">
                  29,000
                </div>
              </div>
            </div>
            <div class="stats_item half_whole">
              <div class="txt_faded">
                <label class="txt_label space_n_b">
                  Red Line
                </label>
                <div class="txt_serif stats_item_number txt_error">
                  22,000
                </div>
              </div>
            </div>
          </section>
      </article>
    </div>
    <h1 class="column h6 color_label">Another Category</h1>
    <div class="column half_whole">
      <article class="card box_panel">
        <label class="card_label">
          Dashboard Item
        </label>
        <section class="card_body">
          <div class="graph">
            <div class="txt_success graph_data txt_serif">2</div>
          </div>
        </section>
        <section class="card_more">
          <div class="color_label card_more_content card_division">
            Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
          </div>
          <a class="icon icon_after card_more_button button_soft"></a>
        </section>
        <section class="stats stats_row">
            <div class="stats_item half_whole">
              <div class="txt_faded">
                <label class="txt_label space_n_b">
                  Goal
                </label>
                <div class="txt_serif stats_item_number txt_success">
                  &lt;4
                </div>
              </div>
            </div>
            <div class="stats_item half_whole">
              <div class="txt_faded">
                <label class="txt_label space_n_b">
                  Red Line
                </label>
                <div class="txt_serif stats_item_number txt_error">
                  &gt;6
                </div>
              </div>
            </div>
          </section>
      </article>
    </div>
    <div class="column half_whole">
      <article class="card box_panel">
        <label class="card_label">
          Dashboard Item
        </label>
        <section class="card_body">
          <div class="graph">
            <div class="txt_error graph_data txt_serif"><sup class="txt_smaller">$</sup> 7,632</div>
          </div>
        </section>
        <section class="card_more">
          <div class="color_label card_more_content card_division">
            Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
          </div>
          <a class="icon icon_after card_more_button button_soft"></a>
        </section>
        <section class="stats stats_row">
            <div class="stats_item half_whole">
              <div class="txt_faded">
                <label class="txt_label space_n_b">
                  Goal
                </label>
                <div class="txt_serif stats_item_number txt_success">
                  $9,000
                </div>
              </div>
            </div>
            <div class="stats_item half_whole">
              <div class="txt_faded">
                <label class="txt_label space_n_b">
                  Red Line
                </label>
                <div class="txt_serif stats_item_number txt_error">
                  $7,750
                </div>
              </div>
            </div>
          </section>
      </article>
    </div>
  </div>
</section>
</template>

<script>
export default {
    
}
</script>

<style scoped>

@import url("https://uwhealth.github.io/Nu-Connect/uconnect_assets/css/main.css");

.wrapper {
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
}
.st0{fill:#D7DDE5;}
.st1, .knob_data {fill:#2EAB6F; color:#2EAB6F;}

.graph {
  width: 80%;
  display: table;
  margin: 0 auto 0;
  position: relative;
  text-align: center;
  height: 12.5rem;
}

.graph_media {
  max-height: 12rem;
}

.graph_data, .knob_data {
  font-size: 3rem;
}

.graph_data {
  display: table-cell;
  vertical-align: middle;
}

.card_division {
  border-top: 1px solid #D7DDE5;
  padding: 0;
}

 .card_body {
   background-image: linear-gradient(#ededed 1px, transparent 1px),
   linear-gradient(90deg, #ededed 1px, transparent 1px);
   background-size: 9px 9px;
 }

.card_full {
  max-height: none;
  height: auto;
}

.card_more_button {
  display: block;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.1;
  transition: transform .12s ease-out;
}

.card_more_button {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #D7DDE5;
  border-top-color: #D7DDE5;
  background-color: #fff;
}

.card_more_button:after {
  	content: '\e629';
}

.card_more_content {
  display: none;
  font-size: .865rem;
  line-height: 1.5;
  padding: .75rem;
}

.stats {
  width: 100%;
  margin-bottom: -2px;
  height: 5.75rem;
  display: table;
}

.stats_row > .stats_item {
  border-left: 1px solid #D7DDE5;
  padding: .75rem 1.5rem;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.stats_row > .stats_item:first-child {
  border-left: 0;
}

.stats_row:after {
  content: ' ';
  display: table;
  clear: both;
}

.stats_item_number {
  white-space: nowrap;
  font-size: 2.25rem;
  line-height: 2.5rem;
  
 
}

.txt_success {
  color: #2EAB6F;
}

.txt_warn {
  color: #DDC728;
}

.txt_smaller {
  font-size: .75em;
}

.flipY {
  transform: scaleY(-1);
  border-bottom-color: #fff;
}

.txt_faded {
  opacity: .65;
}

</style>