<template>
	<div>
		<h2>AMORTISSEMENT</h2>
		<amortissement-component />
	</div>
</template>

<script>
import AmortissementComponent from "../components/AmortissementComponent.vue";
export default {
	components: {
		AmortissementComponent,
	},
	data() {
		return {
			date_debut: "",
			montant: "",
			dure: "",
			taux: "",
			taux_assurance: "",
		};
	},
	methods: {},
};
</script>

<style scoped>
h2 {
	font-size: 25px;
	margin-bottom: 15px;
}
</style>
